export const GAME_STATUS = {
  start: 'start',
  playing: 'playing',
  finish: 'finish',
};

export const QUIZ_STATUS = {
  fresh: 'fresh',
  solved: 'solved',
  solving: 'solving',
  failed: 'failed',
  reveal: 'reveal'
};

export default null;
