import React, { useState } from 'react';
import { Button, Input } from "../Markup";
import { QUIZ_STATUS } from "../../helpers/constants";
import Img from "gatsby-image";
import TextMessage from "./TextMessage";
import GameProgress from "./GameProgress";
import stringSimilarity from "string-similarity";

const initGuess = '';
const minMatchingPercent = 0.8;

const GamePlaying = ({ progress, total, quiz = {}, updateQuiz = () => {}, nextQuiz = () => {} }) => {
  const [guess, setGuess] = useState(initGuess);
  const [isError, setIsError] = useState(false);
  const {
    image: {childImageSharp: {fluid}} = {childImageSharp: {}},
    name: answer,
    status,
    slug,
  } = quiz;

  const handleClickCheckAnswer = (event) => {
    event.preventDefault();

    if (guess && status === QUIZ_STATUS.solving) {
      const similarity = stringSimilarity.compareTwoStrings(munge(guess),munge(answer));
      console.log("Comparing",munge(guess),munge(answer),similarity);
      updateQuiz(slug, {
        status: similarity >= minMatchingPercent ? QUIZ_STATUS.solved : QUIZ_STATUS.failed,
        attempts: quiz.attempts + 1
      });
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const munge = (string) => {
    return string.toLowerCase().replace("a ","").replace("the ","");
  }

  const handleChangeGuess = ({target: { value } = {}}) => {
    setGuess(value);
  };

  const handleClickAgain = () => {
    updateQuiz(slug, {status: QUIZ_STATUS.solving })
  };

  const handleClickReveal = () => {
    updateQuiz(slug, {status: QUIZ_STATUS.reveal })
  };

  const handleClickRevealNext = () => {
    updateQuiz(slug, {status: QUIZ_STATUS.failed });
    handleClickNext()
  };

  const handleClickNext = () => {
    setGuess(initGuess);
    nextQuiz()
  };

  const controlButtons = () => {
    switch (status) {
      case QUIZ_STATUS.solved:
        return (
          <>
            <div>You're right!</div>
            <Button onClick={handleClickNext}>Next</Button>
          </>
        );
      case QUIZ_STATUS.failed:
        return (
          <>
            <TextMessage>Nope</TextMessage>
            <Button onClick={handleClickAgain} className="mx-2">Guess Again</Button>
            <Button onClick={handleClickReveal} className="mx-2">Reveal Answer</Button>
          </>
        );
      case QUIZ_STATUS.reveal:
        return (
          <>
            <TextMessage>It was <code>{answer}</code></TextMessage>
            <Button onClick={handleClickRevealNext}>Next</Button>
          </>
        );
      case QUIZ_STATUS.solving:
      case QUIZ_STATUS.fresh:
      default:
        return (
          <form onSubmit={handleClickCheckAnswer}>
            <div className="field mb-5">
              <div className="control">
                <Input
                  className={isError ? 'is-danger' : ''}
                  onChange={handleChangeGuess}
                  placeholder="Your Guess Here"
                  value={guess}/>
              </div>
              {isError && <p className="help is-danger">Please, type your guess</p>}
            </div>
            <Button onClick={handleClickCheckAnswer} type="submit">Check Answer</Button>
          </form>
        )
    }
  };

  return (
    <div>
      {fluid && <Img  fadeIn={false} loading="eager" fluid={fluid} style={{maxWidth: '900px'}} className="image"/>}
      <div className="my-5">
        {controlButtons()}
      </div>
      <GameProgress progress={progress} total={total} />
    </div>
  )
};

export default GamePlaying;