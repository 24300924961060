import React from 'react';

const TextMessage = ({children, className = ''}) => {
  return (
    <div className={`my-2 ${className}`}>
      {children}
    </div>
  );
};

export default TextMessage;