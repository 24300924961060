import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import GamePlaying from "../components/TurtleFilm/GamePlaying";
import { GAME_STATUS, QUIZ_STATUS} from '../helpers/constants'
import GameStart from "../components/TurtleFilm/GameStart";
import GameFinish from "../components/TurtleFilm/GameFinish";

const initStats = { progress: 0, total: 0, solved: 0, failed: 0, solving: 0, reveal: 0 };

const TurtleFilmIndexTemplate = ({data: { allMarkdownRemark: { edges = [] } }}) => {

  const [status, setStatus] = useState(GAME_STATUS.start);
  const [stats, setStats] = useState(initStats);
  const [quizzes, setQuizzes] = useState([]);
  const [quizIndex, setQuizIndex] = useState(null);
  const { progress, total, solved } = stats;

  const updateQuiz = (slug, data) => {
    let items = quizzes.map(item => (item.slug === slug ? {...item, ...data} : item));
    setQuizzes(items);
  };

  const startGame = () => {
    setStatus(GAME_STATUS.playing);
    nextQuiz();
  };

  const nextQuiz = () => {
    const slugs = quizzes.filter(({status}) => status === QUIZ_STATUS.fresh).map(({slug}) => slug);

    if (slugs.length > 0) {
      const randomSlug = Math.floor(Math.random() * slugs.length);
      let randomQuizIndex = null;
      let items = quizzes.map((item, index) => {
        if (item.slug === slugs[randomSlug]) {
          item.status = QUIZ_STATUS.solving;
          randomQuizIndex = index
        }
        return item
      });
      setQuizzes(items);
      setQuizIndex(randomQuizIndex);
    } else {
      setStatus(GAME_STATUS.finish);
    }
  };

  useEffect(()=> {
    let quizzes = edges.map(({
        node: {
          frontmatter: {film_image, film_name},
          fields: {slug},
      }}) => ({ slug, image: film_image, name: film_name, status: QUIZ_STATUS.fresh, attempts: 0 }));

    setQuizzes(quizzes);
  }, [edges]);

  useEffect(() => {
    let currentStats = {...initStats, total: quizzes.length};
    quizzes.forEach(({status}) => {
      currentStats.progress += 1;
      switch (status) {
        case QUIZ_STATUS.solved:
          currentStats.solved += 1;
          break;
        case QUIZ_STATUS.solving:
          currentStats.solving += 1;
          break;
        case QUIZ_STATUS.failed:
          currentStats.failed += 1;
          break;
        case QUIZ_STATUS.reveal:
          currentStats.reveal += 1;
          break;
        default:
      }
    });

    currentStats.progress = currentStats.solving + currentStats.reveal + currentStats.solved + currentStats.failed;

    setStats(currentStats);
  }, [quizzes]);

  const currentBlock = () => {
    switch (status) {
      case GAME_STATUS.playing:
        return <GamePlaying
                  total={total}
                  progress={progress}
                  quiz={quizzes[quizIndex]}
                  updateQuiz={updateQuiz}
                  nextQuiz={nextQuiz} />;
      case GAME_STATUS.finish:
        return  <GameFinish solved={solved} total={total} />;
      case GAME_STATUS.start:
      default:
        return <GameStart startGame={startGame} />;
    }
  };

  return (
    <Layout>
      <div className="columns has-text-centered">
        <div className="column is-6-tablet is-offset-3-tablet is-4-desktop is-offset-4-desktop">
          {currentBlock()}
        </div>
      </div>
    </Layout>
  )
};

export default TurtleFilmIndexTemplate

export const turtleFilmsQuery = graphql`
  query turtleFilmsQuery {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "turtlefilm-page"}}}) {
      edges {
        node {
          frontmatter {
            film_name
            film_image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`;
