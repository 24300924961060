import React from 'react';
import TextMessage from "./TextMessage";

const GameFinish = ({solved, total}) => {
  return (
    <TextMessage>
      The end! <br/>
      You got {solved} of {total} right!
    </TextMessage>
  );
};

export default GameFinish;