import React from 'react';
import { Button } from "../Markup";
import TextMessage from "./TextMessage";

const GameStart = ({startGame}) => {
  return (
    <>
      <TextMessage className="is-size-5">Are you ready?</TextMessage>
      <Button onClick={startGame}>Play</Button>
    </>
  )
};

export default GameStart;